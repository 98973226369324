















































import { USER_ROLES } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import {
  IProjectCreate,
} from '@/interfaces';
import { readAdminUsers } from '@/store/admin/getters';
import { readProject } from '@/store/main/getters';
import { dispatchAssignProjectForUsers, dispatchGetProject } from '@/store/main/actions';
import { dispatchGetUnassignUsersByProject } from '@/store/admin/actions';

@Component
export default class Assign extends Vue {
  public roles = USER_ROLES;
  public valid = false;
  public pagination = {
    ascending: true,
    sortBy: 'name',
    rowsPerPage: 25,
    page: 1,
  };
  public loading = true;

  public userIds = [];

  public headers = [
    {
      text: 'Username',
      sortable: true,
      value: 'username',
      align: 'left',
    },
    {
      text: 'Email',
      sortable: true,
      value: 'email',
      align: 'left',
    },
    {
      text: 'Full Name',
      sortable: true,
      value: 'full_name',
      align: 'left',
    },
    {
      text: 'Is Active',
      sortable: true,
      value: 'is_active',
      align: 'left',
    },
    {
      text: 'Role',
      sortable: true,
      value: 'role',
      align: 'left',
    },
    // {
    //   text: 'Is Admin',
    //   sortable: true,
    //   value: 'isSuperuser',
    //   align: 'left',
    // },
    {
      text: 'Add users',
      value: 'id',
    },
  ];

  get project() {
    return readProject(this.$store);
  }

  public cancel() {
    this.$router.back();
  }

  get users() {
    const users = readAdminUsers(this.$store).filter((u) => u.type !== 'qr');
    users.forEach(user => {
      user['checked'] = false;
    });
    return users;
  }

  public async mounted() {
    await dispatchGetProject(this.$store, Number(this.$router.currentRoute.params.id))
    if (this.project) {
      await dispatchGetUnassignUsersByProject(this.$store, Number(this.$router.currentRoute.params.id));
    }
    this.loading = false;
  }

  public async submit() {
    this.users.forEach((value) => {
      if(value['checked']){
        this.userIds.push(value.id as never);
      }
    });

    await dispatchAssignProjectForUsers(this.$store, { projectId: Number(this.$router.currentRoute.params.id), data: this.userIds});
    this.$router.back();
  }
}
